import MoonLoader from "react-spinners/MoonLoader";
import './spinner.css'

function Spinner() {

  return (
    <div className="sweet-loading">
      <MoonLoader
        color={'#ffffff'}
        loading={true}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Spinner;