import React from "react";
import "./forecast.css";

const Forecast = ({ data }) => {

  function createDate(timestamp) {
    let options = { day: 'numeric', month: 'numeric' };
    var date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-GB", options);
  }

  function createDateHour(timestamp) {
    var date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });
  }

  return (
    <div className="hourly-container">
      {data.list.slice(0, data.list.length).map((item, idx) => (
        <div className="box" key={idx}>
            <p className="day">{createDate(item.dt)}</p>
            <p className="day">{createDateHour(item.dt)}</p>
            <img src={`icons/${item.weather[0].icon}.svg`} className="icon-small" alt="weather" />
            <p className="min-max">{Math.round(item.main.temp)}°C</p>
        </div>
      ))}
    </div>
  );
};

export default Forecast;
