import React from "react";
import "./current-weather.css";

const CurrentWeather = ({ data }) => {

  function createDate(timestamp) {
    let options = { weekday: 'long', day: 'numeric', month: 'long' };
    var date = new Date(timestamp * 1000, );
    return date.toLocaleDateString("en-GB", options);
  }

  return (
    <div className="weather">
      <div className="top">
        <div>
          <h1 className="city">{data.city}</h1>
          <h4 className="date">{createDate(data.dt)}</h4>
        </div>
      </div>
      <div className="bottom">
        <div className="left-side">
          <img alt="weather" className="weather-icon" src={`icons/${data.weather[0].icon}.svg`} />
          <div className="sub-section">
            <h2 className="temperature">{Math.round(data.main.temp)}°C</h2>
            <h4 className="weather-description">{data.weather[0].description}</h4>
          </div>
        </div>
        <div className="right-side">
          <div className="parameter-row">
            <span className="parameter-label">Feels like</span>
            <span className="parameter-value">
              {Math.round(data.main.feels_like)}°C
            </span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label">High</span>
            <span className="parameter-value">{Math.round(data.main.temp_max)}°C</span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label">Low</span>
            <span className="parameter-value">{Math.round(data.main.temp_min)}°C</span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label">Wind</span>
            <span className="parameter-value">{data.wind.speed} m/s</span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label">Humidity</span>
            <span className="parameter-value">{data.main.humidity}%</span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label">Pressure</span>
            <span className="parameter-value">{data.main.pressure} hPa</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentWeather;
