import './App.css';
import { useState, useEffect } from 'react';
import Search from './components/search/search';
import CurrentWeather from './components/current-weather/current-weather';
import Forecast from './components/forecast/forecast';
import { WEATHER_API_KEY, WEATHER_API_URL } from './api';
import Spinner from './components/spinner/spinner';

function App() {

  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecast, setForecast] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);

  useEffect(() => {
    if (searchData) {
      const [lat, lon] = searchData.value.split(" ");
      setLoading(true);
      const currentWeatherFetch = fetch(`${WEATHER_API_URL}/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`);
      const forecastFetch = fetch(`${WEATHER_API_URL}/forecast?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`);

      Promise.all([currentWeatherFetch, forecastFetch])
        .then(async (response) => {
          const weatherResponse = await response[0].json();
          const forecastResponse = await response[1].json();

          setCurrentWeather({city: searchData.label, ...weatherResponse});
          setForecast({city: searchData.label, ...forecastResponse});
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [searchData]);

  const handleOnSearchChange = (searchData) => {
    setSearchData(searchData);
  }

  useEffect(() => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        const currentWeatherFetch = fetch(`${WEATHER_API_URL}/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`);
        const forecastFetch = fetch(`${WEATHER_API_URL}/forecast?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`);

        Promise.all([currentWeatherFetch, forecastFetch])
          .then(async (response) => {
            const weatherResponse = await response[0].json();
            const forecastResponse = await response[1].json();

            setCurrentWeather({city: `${weatherResponse.name}, ${weatherResponse.sys.country}`, ...weatherResponse});
            setForecast({city: `${weatherResponse.name}, ${weatherResponse.sys.country}`, ...forecastResponse});
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, []); // Run this effect only once, on component mount

  return (
    <div className="container">
      <Search onSearchChange={handleOnSearchChange}/>
      { loading && <Spinner /> }
      { currentWeather && <CurrentWeather data={currentWeather} />}
      { forecast && <Forecast data={forecast} /> }
    </div>
  );
}

export default App;
